module.exports = {
    AppName: "租机",
    AppSimpleName: "租机",
    FooterText: "Ant Design ©2018 Created by Ant UED",
    HomePage: "/",
    UploadFileUrl: "https://img.hangzhoushuangchen.com/upload/files",
    LockPage: "/lock",
    CaptchaUrl: "/captcha",
    LoginPage: "https://admin.lsshenyan.com/",
    AuthUrl: "https://admin.lsshenyan.com/apps",
    InitMenu: false,
    USERINFO_KEY: "rent_userinfo",
    SECRET_KEY: "rent_secret",
    LOCAL_URL_KEY: "rent_local_url",
    BaseUrl: "https://rent.xiaomifengd.com/api",
}
